import React, { useState } from 'react';
import axios from "axios";
import "../CSS/ContactForm.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL = 'https://backend-5uyg.onrender.com/api/feedback';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        mensaje: '',
        nombre: '',
        email: '',
        motivo: ''
    });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'mensaje' && value.length > 300) {
            const truncatedValue = value.slice(0, 300);
            setFormData({
                ...formData,
                [name]: truncatedValue
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validar campos antes de enviar

        if (!formData.motivo.trim()) {
            toast.warning('Por favor, selecciona un motivo de contacto.');
            return;
        }

        if (!formData.nombre.trim()) {
            toast.warning('Por favor, ingresa tu nombre.');
            return;
        }

        if (!formData.email.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            toast.warning('Por favor, ingresa un correo electrónico válido. Ejemplo: nombre@gmail.com');
            return;
        }

        if (!formData.mensaje.trim()) {
            toast.warning('Por favor, ingresa un mensaje.');
            return;
        }

        try {
            const response = await axios.post(API_URL, formData);
            if (response.status === 200) {
                toast.success('Tu mensaje ha sido enviado correctamente. Espera nuestra respuesta.');
                setFormData({
                    mensaje: '',
                    nombre: '',
                    email: '',
                    motivo: ''
                });
            } else {
                toast.error('Ocurrió un error. Inténtalo de nuevo.');
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
            toast.error('Ocurrió un error al enviar el mensaje. Inténtalo de nuevo más tarde.');
        }
    };

    return (
        <div className="formulario-contacto-container">
            <form onSubmit={handleSubmit}>
            <div className='toast-container'>
                    <ToastContainer
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{
                            width: isMobile ? "90%" : "35%", // Ancho responsivo según si es móvil o no
                            fontSize: "1.2rem", // Tamaño de fuente responsivo
                            top: isMobile ? "15%" : "23%", // Posición desde arriba responsiva
                            left: isMobile ? "50%" : "82%", // Posición desde la izquierda (centrado horizontalmente)
                            transform: isMobile ? "translateX(-50%)" : "translate(-50%, -50%)" // Centrado horizontal y vertical si es móvil
                        }}
                        closeButton={
                            <button
                                className="toast-close-button-custom"
                                style={{ width: 50, height: 50, borderRadius: 5 }}
                            >
                                X
                            </button>
                        }
                    />
                </div>
                <h2 id='ww'>Contáctanos</h2>
                <label htmlFor="motivo">Motivo de Contacto:</label>
                <select
                    id="motivo"
                    name="motivo"
                    value={formData.motivo}
                    onChange={handleChange}
                >
                    <option value="">Seleccione el motivo de contacto</option>
                    <option value="consulta">Consultas</option>
                    <option value="soporte">Soporte</option>
                    <option value="sugerencia">Sugerencias</option>
                    <option value="queja">Quejas</option>
                </select>
                <label htmlFor="nombre">Ingrese su nombre:</label>
                <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={formData.nombre}
                    onChange={handleChange}
                />
                <label htmlFor="email">Ingrese su e-mail:</label>
                <input
                    type="text"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                />
                <label htmlFor="mensaje">Mensaje:</label>
                <div className="textarea-container">
                    <textarea
                        id="mensaje"
                        name="mensaje"
                        value={formData.mensaje}
                        onChange={handleChange}
                    ></textarea>
                    <div className={`character-count ${formData.mensaje.length > 300 ? 'character-count-exceeded' : ''}`}>
                        {formData.mensaje.length}/300
                    </div>
                </div>
                <button type="submit" id='b2' className='submit-button'>Enviar</button>
            </form>
        </div>
    );
};

export default ContactForm;
