import React, { useState, useEffect } from "react";
import "../CSS/ProductCrud.css";
import axios from "axios"; // Import axios

const API_URL = "https://backend-5uyg.onrender.com/api/addEmployee";

function UsersCrud() {
    const [users, setUsers] = useState([]);
    const [editedUserIndex, setEditedUserIndex] = useState(null);
    const [showForm, setShowForm] = useState(false);

    useEffect(() => {
        fetchData();
    }, []); // Se ejecuta una sola vez al cargar el componente

    const fetchData = async () => {
        try {
            const response = await fetch("https://backend-5uyg.onrender.com/api/employee");
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error("Error al cargar los usuarios:", error);
        }
    };
    const [step, setStep] = useState(1); // Controla el paso actual del formulario

    const [formData, setFormData] = useState({
        Nombre: "",
        NomUsuario: "",
        Apaterno: "",
        Amaterno: "",
        Psswd: "",
        Telefono: "",
        Email: "",
        RFC: "",
        Tipo: "",
        confirmpassword: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        console.log(formData);
    };

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };
    
    const handleCancel = () => {
        setStep(1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(API_URL, formData);
            if (response.status === 200) {
                fetchData();
                console.log("Dispositivo creado correctamente:", response.data);
                // Clear the form after successful insertion (optional)
                console.log("Datos del empleado:", formData);
                setFormData({
                    Nombre: '',
                    NomUsuario: '',
                    Apaterno: '',
                    Amaterno: '',
                    Psswd: '',
                    Telefono: '',
                    Email: '',
                    RFC: '',
                    Tipo: '',
                    confirmpassword: ""
                });
            } else {
                console.log(formData)
                console.error("Error al crear el dispositivo:", response.data);
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };

    const handleDelete = async (nombre) => {
        try {
            const response = await axios.delete(`https://backend-5uyg.onrender.com/api/employee/${nombre}`);
            console.log("Dispositivo elminado correctamente:", response.data);
            // Actualizar la lista de dispositivos después de eliminar el dispositivo
            fetchData();
        } catch (error) {
            console.error("Error al eliminar el dispositivo:", error);
        }
    };

    const handleEdit = (index) => {
        setEditedUserIndex(index);
        const userToEdit = users[index];
        setFormData(userToEdit);
    };


    const handleSave = async(nombre) => {
        try {
            const response = await axios.post(`https://backend-5uyg.onrender.com/api/employee/${nombre}`, formData);
            if (response.status === 200) {
                fetchData();
                console.log("Dispositivo creado correctamente:", response.data);
                console.log("Datos del empleado:", formData);
            } else {
                console.log(formData)
                console.error("Error al crear el dispositivo:", response.data);
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
        setEditedUserIndex(null);
        setFormData({
            Nombre: '',
            NomUsuario: '',
            Apaterno: '',
            Amaterno: '',
            Psswd: '',
            Telefono: '',
            Email: '',
            RFC: '',
            Tipo: '',
            confirmpassword: ""
        });
    };

    return (
        <div className="ProductCrud-container">
            {showForm ? (
            <section className="Form-section">
                <h2>Agregar Empleado</h2>
                {step === 1 && (
                    <form onSubmit={handleSubmit} className="form">
                        <span >Datos de Generales - Paso 1 de 3</span>
                        <label htmlFor="employeeFirstName">Nombre:</label>
                        <input
                            type="text"
                            id="employeeFirstName"
                            name="Nombre"
                            value={formData.Nombre}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="employeeLastName">Apellido Paterno:</label>
                        <input
                            type="text"
                            id="employeeLastName"
                            name="Apaterno"
                            value={formData.Apaterno}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="employeeLastName">Apellido Materno:</label>
                        <input
                            type="text"
                            id="employeeLastName"
                            name="Amaterno"
                            value={formData.Amaterno}
                            onChange={handleChange}
                            required
                        />
                        <div className="buttons">
                        <button type="button" onClick={handleNext}>Siguiente</button>
                        <button onClick={() => { setShowForm(false); handleCancel(); }}>Cancelar</button>
                        </div>
                    </form>
                )}
                {step === 2 && (
                    <form onSubmit={handleSubmit} className="form">
                        <span >Datos de Usuario - Paso 2 de 3</span>
                        <label htmlFor="employeeUsername">Nombre de Usuario:</label>
                        <input
                            type="text"
                            id="employeeUsername"
                            name="NomUsuario"
                            value={formData.NomUsuario}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="employeeEmail">Correo:</label>
                        <input
                            type="email"
                            id="employeeEmail"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="employeePhone">Teléfono:</label>
                        <input
                            type="number"
                            id="employeePhone"
                            name="Telefono"
                            value={formData.Telefono}
                            onChange={handleChange}
                            required
                        />
                        <div className="buttons">
                        <button type="button" onClick={handlePrevious}>Anterior</button>
                        <button type="button" onClick={handleNext}>Siguiente</button>
                        <button onClick={() => { setShowForm(false); handleCancel(); }}>Cancelar</button>
                        </div>
                    </form>
                )}
                {step === 3 && (
                    <form onSubmit={handleSubmit} className="form">
                        <span >Datos Empresariales - Paso 3 de 3</span>
                        <label htmlFor="employeeRFC">RFC:</label>
                        <input
                            type="text"
                            id="employeeRFC"
                            name="RFC"
                            value={formData.RFC}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="employeeType">Tipo:</label>
                        <select
                            id="employeeType"
                            name="Tipo"
                            value={formData.Tipo}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Selecciona un tipo</option>
                            <option value="Empleado">Empleado</option>
                            <option value="Administrador">Administrador</option>
                        </select>
                        <label htmlFor="employeePassword">Contraseña:</label>
                        <input
                            type="password"
                            id="employeePassword"
                            name="Psswd"
                            value={formData.Psswd}
                            onChange={handleChange}
                            required
                        />
                        <label htmlFor="employeeConfirmPassword">Confirmar Contraseña:</label>
                        <input
                            type="password"
                            id="employeeConfirmPassword"
                            name="confirmpassword"
                            value={formData.confirmpassword}
                            onChange={handleChange}
                            required
                        />
                        <div className="buttons">
                            <button type="button" onClick={handlePrevious}>Anterior</button>
                            <button type="submit">Registrar</button>
                            <button onClick={() => { setShowForm(false); handleCancel(); }}>Cancelar</button>
                        </div>
                    </form>
                )}
            </section>
            ) : (
                <button className="btn" onClick={() => setShowForm(true)}>Agregar Nuevo</button>
            )}
            <section className="Table-section">
                <h2>Lista de Usuarios</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre(s)</th>
                            <th>Apellido Paterno</th>
                            <th>Apellido Materno</th>
                            <th>Correo</th>
                            <th>Teléfono</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={index}>
                                <td>
                                    {editedUserIndex === index ? (
                                        <input
                                            type="text"
                                            name="Nombre"
                                            value={formData.Nombre}
                                            onChange={(e) => handleChange(e, "nombre")}
                                        />
                                    ) : (
                                        <span>{user.Nombre}</span>
                                    )}
                                </td>
                                <td>
                                    {editedUserIndex === index ? (
                                        <input
                                            type="text"
                                            name="Apaterno"
                                            value={formData.Apaterno}
                                            onChange={(e) => handleChange(e, "apellidoPaterno")}
                                        />
                                    ) : (
                                        <span>{user.Apaterno}</span>
                                    )}
                                </td>
                                <td>
                                    {editedUserIndex === index ? (
                                        <input
                                            type="text"
                                            name="Amaterno"
                                            value={formData.Amaterno}
                                            onChange={(e) => handleChange(e, "apellidoMaterno")}
                                        />
                                    ) : (
                                        <span>{user.Amaterno}</span>
                                    )}
                                </td>
                                <td>
                                    {editedUserIndex === index ? (
                                        <input
                                            type="text"
                                            name="Email"
                                            value={formData.Email}
                                            onChange={(e) => handleChange(e, "correo")}
                                        />
                                    ) : (
                                        <span>{user.Email}</span>
                                    )}
                                </td>
                                <td>
                                    {editedUserIndex === index ? (
                                        <input
                                            type="text"
                                            name="Telefono"
                                            value={formData.Telefono}
                                            onChange={(e) => handleChange(e, "telefono")}
                                        />
                                    ) : (
                                        <span>{user.Telefono}</span>
                                    )}
                                </td>
                                <td>
                                    {editedUserIndex === index ? (
                                        <div>
                                            <button onClick={() => handleSave(user._id)}>Guardar</button>
                                            <button onClick={() => setEditedUserIndex(null)}>Cancelar</button>
                                        </div>
                                    ) : (
                                        <div>
                                            <button onClick={() => handleEdit(index)}>Editar</button>
                                            <button onClick={() => handleDelete(user.NomUsuario)}>Eliminar</button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div>
    );
}

export default UsersCrud;
