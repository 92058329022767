import React, { useState, useEffect } from "react";
import "../CSS/ProductCrud.css";
import axios from "axios"; // Import axios

const API_URL = "https://backend-5uyg.onrender.com/api/addDevice"; // Define API endpoint URL
const FETCH_DEVICES_URL = "https://backend-5uyg.onrender.com/api/devices";
const FETCH_USERS_URL = "https://backend-5uyg.onrender.com/api/users";

function IoTCrud() {
    const [devices, setDevices] = useState([]);
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({
        usuario: '',
    });

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (e, deviceName) => {
        const { value } = e.target;
        setDevices(prevDevices => prevDevices.map(device => {
            if (device.Nombre === deviceName) {
                return {
                    ...device,
                    formData: {
                        ...device.formData,
                        usuario: value
                    }
                };
            }
            return device;
        }));
    };

    const fetchData = async () => {
        try {
            const devicesResponse = await fetch(FETCH_DEVICES_URL);
            const devicesData = await devicesResponse.json();
            if (devicesData && devicesData.length > 0) {
                // Añadir el documento sin vincular
                const updatedDevices = devicesData.map(device => {
                    if (!device.IdCliente) {
                        return {
                            ...device,
                            NombreCliente: 'Sin vincular',
                            formData: {
                                usuario: ''
                            }
                        };
                    }
                    return device;
                });
                setDevices(updatedDevices);
            } else {
                throw new Error("No se encontraron dispositivos");
            }

            const usersResponse = await fetch(FETCH_USERS_URL);
            const usersData = await usersResponse.json();
            if (usersData && usersData.length > 0) {
                setUsers(usersData);
            } else {
                throw new Error("No se encontraron usuarios");
            }
        } catch (error) {
            console.error("Error al cargar los datos:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(API_URL, formData);
            if (response.status === 201) {
                console.log("Dispositivo creado correctamente:", response.data);
                fetchData();
            } else {
                console.error("Error al crear el dispositivo:", response.data);
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };

    const handleAssignUser = async (deviceName, deviceClave) => {
        const device = devices.find(device => device.Nombre === deviceName);
        if (device.NombreCliente === 'Sin vincular') {
            try {
                const data = { usuario: device.formData.usuario, clave: deviceClave }; 
                console.log(data)// Renombrar 'Clave' a 'clave' para que coincida con el servidor
                const response = await axios.put(`https://backend-5uyg.onrender.com/api/assignUser/${deviceName}`, data); // Cambiar a POST
                if (response.status === 200) {
                    console.log("Usuario asignado correctamente:", response.data);
                    fetchData();
                } else {
                    console.error("Error al asignar el usuario:", response.data);
                }
            } catch (error) {
                console.error("Error en la solicitud:", error);
            }
        } else {
            console.log("El dispositivo ya tiene un usuario asignado");
        }
    };
    

    return (
        <div className="ProductCrud-container">
            <section className="Form-section">
                <h2>Nuevo Dispositivo IoT</h2>
                <form onSubmit={handleSubmit} className="form">
                    <button type="submit">Crear Nuevo</button>
                </form>
            </section>
            <section className="Table-section">
                <h2>Lista de Dispositivos</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Clave</th>
                            <th>Usuario</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {devices.map((device) => (
                            <tr key={device.Clave}>
                                <td>{device.Nombre}</td>
                                <td>{device.Clave}</td>
                                <td>{device.NombreCliente === 'Sin vincular' ? (
                                    <select
                                        id="assignedUser"
                                        name="usuario"
                                        value={device.formData.usuario}
                                        onChange={(e) => handleChange(e, device.Nombre)}
                                    >
                                        <option value="">Selecciona un usuario</option>
                                        {users.map((user, index) => (
                                            <option key={user.Usuario} value={user._id}>{user.Usuario}</option>
                                        ))}
                                    </select>
                                ) : (
                                    <span>Asignado a: {device.NombreCliente}</span>
                                )}</td>
                                <td>
                                    {device.NombreCliente === 'Sin vincular' && (
                                        <button className="assign" onClick={() => handleAssignUser(device.Nombre, device.Clave)}>
                                            Asignar
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div>
    );
}

export default IoTCrud;
