import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function AdminWelcome() {
  const [userData, setUserData] = useState(null);
  const [img, setImg] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo && userInfo._id && userInfo.name) {
        setUserData([userInfo._id, userInfo.name, userInfo.userType]);
      }
    },1000); // 6 segundos en milisegundos

    return () => clearInterval(interval);

  }, []);

  useEffect(() => {
    fetch('https://backend-5uyg.onrender.com/api/policy')
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0 && data[0].visión) {
          setImg(data[0].img);
        } else {
          throw new Error("Los datos no contienen las políticas de privacidad");
        }
      })
      .catch(error => console.error("Error al cargar las políticas:", error));
  }, []);


  return (
    <section className="Welcome-section">
      <h2>Bienvenido de vuelta {userData?.[1]}!</h2>
      <div className="carousel-container">
        <Carousel  autoPlay showArrows={false} showThumbs={false} interval={1000}>
          {img.map((image, index) => (
            <div key={index}>
              <img src={image.url} alt={`Slide ${index}`} style={{
                width: '500px', // Ajusta el ancho según tu diseño
                height: '300px', // Ajusta la altura según tu diseño
              }} />
            </div>
          ))}
        </Carousel>
      </div>
      <h2> Despliega el menú de la esquina superior izquierda para comenzar a administrar tu negocio</h2>
    </section>
  );
}

export default AdminWelcome;
