import React, { useState, useEffect } from "react";
import logo from '../assets/LOGO.png';
import { cerrarSesion } from '../Public/SessionService';

function AdminHeader() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleLogout = () => {
        // Limpiar el almacenamiento local al cerrar sesión
        localStorage.removeItem('userInfo');
        cerrarSesion();
        // Redirigir al usuario a la página de inicio de sesión
        window.location.href = '/';
    };

    return (
        <header className="App-header">
            <div className="menu">
                <div className="menu-right">
                    <img src={logo} className="App-logo" alt="logo" />
                </div>
                <div className="menu-center">PAPER PLUS</div>
                <div className="menu-left">
                    {isMobile ? (
                        <div>
                            <div className={`menu-left ${isMenuOpen ? 'active' : 'inactive'}`} onClick={toggleMenu}>
                                <i className="fas fa-bars"></i> {/* Use Font Awesome icon */}
                                {isMenuOpen && (
                                    <div className="sub-menu">
                                        <a className="menu-button" href="/admin/ProductsManagement">Gestión de Productos</a>
                                        <a className="menu-button" href="/admin/UsersManagement">Gestion de Usuarios</a>
                                        <a className="menu-button" href="/admin/ProvsManagement">Gestión de Proveedores</a>
                                        <a className="menu-button" href="/admin/IoTManagement">Asignación de IoT</a>
                                        <a className="menu-button" href="/admin/InfoManagement">Gestión de Imformación Empresarial</a>
                                        <a className="menu-button" href="/admin/FAQS">Actualización de FAQs</a>
                                        <a className="menu-button" href="/admin/PoliciesInfo">Actualización de Politicas</a>
                                        <a className="menu-button" href="/" onClick={handleLogout}>Cerrar Sesión</a>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div>
                                <a className="menu-button" href="/" onClick={handleLogout}>Cerrar Sesión</a>
                            </div>
                            <div className={`menu-left ${isMenuOpen ? 'active' : 'inactive'}`} onClick={toggleMenu}>
                                <i className="fas fa-bars"></i> {/* Use Font Awesome icon */}
                                {isMenuOpen && (
                                    <div className="sub-menu">
                                        <a className="menu-button" href="/admin/ProductsManagement">Gestión de Productos</a>
                                        <a className="menu-button" href="/admin/UsersManagement">Gestion de Usuarios</a>
                                        <a className="menu-button" href="/admin/ProvsManagement">Gestión de Proveedores</a>
                                        <a className="menu-button" href="/admin/IoTManagement">Asignación de IoT</a>
                                        <a className="menu-button" href="/admin/InfoManagement">Gestión de Imformación Empresarial</a>
                                        <a className="menu-button" href="/admin/FAQS">Actualización de FAQs</a>
                                        <a className="menu-button" href="/admin/PoliciesInfo">Actualización de Politicas</a>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </header>
    );
}

export default AdminHeader;
