import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../CSS/Details.css'; // Importa el archivo CSS con los estilos
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';  // or import './styles/image-gallery.css' if using a separate stylesheet


function ProductDetails() {
    const [product, setProduct] = useState(null);
    const { productName } = useParams();

    useEffect(() => {
        const productInfo = JSON.parse(localStorage.getItem('prodInfo'));
        if (productInfo) {
            fetchDataById(productInfo._id);
        }
    }, []);

    const fetchDataById = async (productId) => {
        try {
            const response = await fetch(`https://backend-5uyg.onrender.com/api/prods/${productId}`);
            const data = await response.json();
            setProduct(data[0]);
        } catch (error) {
            console.error(error);
        }
    };

    if (!product) {
        return <div>{ }</div>;
    }

    const images = product.imagen.map((imagen) => ({
        original: imagen.url,
        thumbnail: imagen.url, // You can also provide a separate thumbnail URL here
    }));


    return (
        <section className='main'>
            <div className="product-details-container">
                <div className="product-details">
                    <div className="image-gallery-container">
                        <ImageGallery items={images} />
                    </div>
                    <div className="product-details-info">
                        <h2>{product.Nombre}</h2>
                        <p className="price">Precio: ${product.Precio}</p>
                        <p className="stock">Stock: {product.StockActual}</p>
                        <p className="category">Categoría: {product.Categoria}</p>
                        <p className="brand">Marca: {product.Marca}</p>
                        <p className="description">Descripción: {product.Descripcion}</p>
                        {/* Aquí puedes renderizar otros detalles del producto según sea necesario */}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProductDetails;
