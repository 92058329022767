import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../CSS/F&P.css";

function EditableField({ label, value, onSave }) {
    const [mode, setMode] = useState('view');
    const [fieldValue, setFieldValue] = useState(value);

    useEffect(() => {
        setFieldValue(value);
    }, [value]); // Actualiza fieldValue cuando el prop value cambia

    const toggleEditMode = () => {
        setMode(mode === 'view' ? 'edit' : 'view');
    };

    const handleSave = () => {
        onSave(fieldValue); // Aquí se guarda el valor solo cuando se hace clic en "Guardar"
        setMode('view');
    };

    const handleCancel = () => {
        setFieldValue(value);
        setMode('view');
    };

    return (
        <div className="Faq-item">
            <h3>{label}</h3>
            {mode === 'view' ? (
                <p>{value}</p>
            ) : (
                <textarea
                    value={fieldValue}
                    onChange={(e) => setFieldValue(e.target.value)}
                />
            )}
            {mode === 'view' ? (
                <button onClick={toggleEditMode}>Editar</button>
            ) : (
                <>
                    <button onClick={handleSave}>Guardar</button>
                    <button onClick={handleCancel}>Cancelar</button>
                </>
            )}
        </div>
    );
}

function FAQsInfo() {
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {
        fetch('https://backend-5uyg.onrender.com/api/policy')
            .then(response => response.json())
            .then(data => {
                setFaqs(data[0].faq);
            })
            .catch(error => console.error("Error al cargar las FAQ:", error));
    }, [faqs]);

    const saveField = async (index, value) => {
        try {
            const response = await axios.post(`https://backend-5uyg.onrender.com/api/faq/${index}`, { value });
            console.log("Campo guardado exitosamente:", response.data);
        } catch (error) {
            console.error("Error al guardar el campo:", error);
        }
    };
    

    return (
        <section className="FAQsInfo-section">
            <h2>Preguntas Frecuentes</h2>
            <div className="Faq-container">
                {faqs.map((faq, index) => (
                    <EditableField
                        key={index} // Usamos el id del elemento como clave
                        label={faq.pregunta}
                        value={faq.respuesta}
                        onSave={(value) => saveField(index, value)} // Pasamos el índice como primer argumento
                    />
                ))}
            </div>
        </section>
    );
}

export default FAQsInfo;
