import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../CSS/Details.css'; // Importa el archivo CSS con los estilos

function ProdCats() {
    const [product, setProduct] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [productosPerPage] = useState(8);
    const { catName } = useParams();

    const paginate = pageNumber => setCurrentPage(pageNumber);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData( catName)
        },500); // 6 segundos en milisegundos

        return () => clearInterval(interval);
    }, [catName]);

    const fetchData = async (catName) => {
        try {
            const response = await fetch(`https://backend-5uyg.onrender.com/api/prodsByCat/${catName}`);
            const data = await response.json();
            setProduct(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleDetails = (prod) => {
        // Guardar la información completa del producto en localStorage
        localStorage.setItem('prodInfo', JSON.stringify(prod));
    }

    const indexOfLastProduct = currentPage * productosPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productosPerPage;
    const currentProducts = product.slice(indexOfFirstProduct, indexOfLastProduct);

    if(product.length === 0) {
        return (
            <div className='loading'>
                <h1>No hay productos de esta categoría aún</h1>
                <h3>Explora algunas de nuestras categorías</h3>
            </div>
        );
    }

    return (
        <section className="Welcome-section">
            <h2>Nuestro catálogo de productos</h2>
            <div className='main-cont'>
                {currentProducts.map((prod, index) => (
                    <div key={index} className="card">
                        <img className='mainImg' src={prod.imagen[0].url} alt={prod.Nombre}></img>
                        <p className='title'>{prod.Nombre}</p>
                        <p className='text'>Precio: {prod.Precio}</p>
                        <p className='text'>Stock: {prod.StockActual}</p>
                        <div>
                            <Link to={`/ProductDetails/${prod.Nombre.replace(/\s+/g, '-').toLowerCase()}`}>
                                <button className="det" onClick={() => handleDetails(prod)}>Detalles</button>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            {/* Paginación */}
            <ul className="pagination">
                {Array.from({ length: Math.ceil(product.length / productosPerPage) }).map((_, index) => (
                    <li key={index} className="page-item">
                        <button onClick={() => paginate(index + 1)} className="page-link">
                            {index + 1}
                        </button>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default ProdCats;
