import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThermometerHalf, faTint, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import '../CSS/IoT.css';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import axios from "axios"; // Import axios
import moment from 'moment';
import './Typo Formal Bold Italic Demo-normal.js';
import ventanaAbiertaImg from '../assets/ventana (2).png';
import ventanaCerradaImg from '../assets/ventana (1).png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function MyIoT() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [data, setData] = useState(null);
    const [nameDevice, setnameDevice] = useState(null);
    const [isLocked, setIsLocked] = useState(false);
    const [deviceId, setDeviceId] = useState(null);
    const [lastStateMessage, setLastStateMessage] = useState('Último estado:');
    const [reportData, setReportData] = useState();
    const [hasDevice, setHasDevice] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [showAddDeviceForm, setShowAddDeviceForm] = useState(false);
    const [uid, setUid] = useState();
    const [userData, setUserData] = useState({ devices: [] }); // Example structure of userData
    const [formData, setFormData] = useState({
        usuario: '',
        nombre: '',
        clave: ''
    });

    const [selectedDate, setSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    // Resto del código...

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    const calculateHumidityColor = (humidity) => {
        if (humidity === 0) {
            return "#46B4F0";
        } else if (humidity <= 20) {
            return "#467DEF";
        } else if (humidity <= 27) {
            return "#F0C732";
        } else if (humidity <= 36) {
            return "#F2722C";
        } else {
            return "#F02F29";
        }
    };

    const calculateTemperatureColor = (temperature) => {
        if (temperature === 0) {
            return "#46B4F0";
        } else if (temperature <= 15) {
            return "#467DEF";
        } else if (temperature <= 30) {
            return "#F0C732";
        } else if (temperature <= 60) {
            return "#F2722C";
        } else {
            return "#F02F29";
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const user = JSON.parse(localStorage.getItem('userInfo'));
            if (user && user._id && user.name) {
                fetchData(user._id);
                setUid(user._id)
            }
            if (selectedDevice) {
                fetchSelectedDeviceData(selectedDevice._id);
                setIsLocked(selectedDevice?.Estado?.seguro === 'Cerrado');
            }
        }, 500);
        return () => clearInterval(interval);
    }, [selectedDevice]); // Ejecutar efecto cuando selectedDevice cambie



    const fetchData = async (userId) => {
        try {
            const response = await fetch(`https://backend-5uyg.onrender.com/api/userIoT/${userId}`);
            const responseData = await response.json();
            if (responseData && responseData.length > 0) {
                setUserData(responseData)
                setData(responseData[0].Dispositivo);
                setnameDevice(responseData[0]);
                setDeviceId(responseData[0].Dispositivo._id);
                setHasDevice(true);
                if (deviceId != null && selectedDate != null) {
                    fetchHistorial(deviceId, selectedDate);
                }
            } else {
                setData({}); // Set data to an empty object if no device is found
                setHasDevice(false); // Set hasDevice to false to show the form
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchSelectedDeviceData = async (deviceId) => {
        try {
            const response = await fetch(`https://backend-5uyg.onrender.com/api/deviceId/${deviceId}`);
            const responseData = await response.json();
            if (responseData && responseData.length > 0) {
                setSelectedDevice(responseData[0]); // Actualizar datos del dispositivo seleccionado
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchHistorial = async (deviceId, fecha) => {
        try {
            const response = await axios.get(`https://backend-5uyg.onrender.com/api/device/${deviceId}?fecha=${fecha}`, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const responseData = response.data;
            if (responseData && responseData.length > 0) {
                setReportData(responseData);
            } else {
                setReportData(responseData);
            }
        } catch (error) {
            console.error(error);
        }
    };




    const handleOpenWindow = async () => {
        try {
            await axios.post('https://backend-5uyg.onrender.com/api/control', { ventanaAbierta: true });
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseWindow = async () => {
        try {
            await axios.post('https://backend-5uyg.onrender.com/api/control', { ventanaAbierta: false });
        } catch (error) {
            console.error(error);
        }
    };

    const generarPDF = () => {

        const doc = new jsPDF();

        doc.setFont('Typo Formal Bold Italic Demo');

        const columns = ['Estado', 'Fecha', 'Hora', 'Mensaje'];
        const columnWidths = [35, 35, 35, 75]; // Anchuras personalizadas de las columnas
        let data = [];

        // Agregar el título
        doc.setFontSize(20);
        doc.text(`Reporte del Dispositivo: ${nameDevice.Dispositivo.Nombre}`, doc.internal.pageSize.getWidth() / 2, 15, { align: 'center' });

        // Verifica si no hay datos disponibles
        if (!reportData || reportData.length === 0 || reportData[0].Historial === null) {
            toast.warning('No hay datos disponibles para generar el PDF.');
            return;
        } else {
            reportData.forEach((historial) => {
                historial.Historial.forEach((estado) => {
                    const rowData = [
                        estado.Estado.seguro || "N/A",
                        estado.Estado.fecha || "N/A",
                        estado.Estado.hora || "N/A",
                        estado.Estado.msg || "N/A"
                    ];
                    data.push(rowData);
                });
            });
        }

        doc.autoTable({
            startY: 30,
            head: [columns],
            body: data,
            styles: {
                font: 'Typo Formal Bold Italic Demo', // Establecer la fuente para la tabla
                fontSize: 12,
            },
            columnStyles: { 0: { cellWidth: columnWidths[0] }, 1: { cellWidth: columnWidths[1] }, 2: { cellWidth: columnWidths[2] }, 3: { cellWidth: columnWidths[3] } }
        });

        doc.save(`reporte_${nameDevice.Dispositivo.Nombre}.pdf`);
        toast.success('Generando reporte PDF de los últimos estados...');
    };


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            formData.usuario = uid;
            const response = await axios.post(`https://backend-5uyg.onrender.com/api/addDeviceUser/${formData.clave}`, formData);
            if (response.status === 200) {
                toast.success("Usuario asignado correctamente:");
                fetchData();
            } else {
                toast.error("Error al asignar el usuario");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeviceChange = async (event) => {
        const selectedDeviceId = event.target.value;
        const selectedDevice = userData.find(device => device.Dispositivo._id === selectedDeviceId)?.Dispositivo;
        setSelectedDevice(selectedDevice); // Actualiza el estado del dispositivo seleccionado
    };



    const handleAddDeviceButtonClick = () => {
        setShowAddDeviceForm(true);
    };

    const handleCancelAddDevice = () => {
        setShowAddDeviceForm(false);
    };

    const [sidebarStyle, setSidebarStyle] = useState({});

    useEffect(() => {
        // Verificar si hay un dispositivo seleccionado y ajustar los estilos de la barra lateral en consecuencia
        if (selectedDevice) {
            setSidebarStyle({}); // Restablecer los estilos originales
        } else {
            // Establecer los estilos centrados para la barra lateral cuando no hay dispositivo seleccionado
            setSidebarStyle({
                width: '100%',
                textAlign: 'center',
                marginRight: '0',
                display: 'flex', // Agregar display flex
                justifyContent: 'center',
                margin: '5%',
            });
        }
    }, [selectedDevice]);

    return (
        <section className='global'>
            <div className="sidebar" style={sidebarStyle}>
                <div className="sidebarCont">
                    {!hasDevice && !showAddDeviceForm && (
                        <div className="device-form-container">
                            <form onSubmit={handleFormSubmit}>
                                <label htmlFor="deviceName">Nombre del dispositivo:</label>
                                <input type="text" id="deviceName" name="nombre" onChange={handleChange} required />
                                <label htmlFor="deviceKey">Clave de vinculación:</label>
                                <input type="number" id="deviceKey" name="clave" onChange={handleChange} required pattern="^[0-9]+$" />
                                <button type="submit">Registrar dispositivo</button>
                            </form>
                        </div>
                    )}
                    {hasDevice && (
                        <div className="device-selector-container">
                            <select onChange={handleDeviceChange}>
                                <option value={null}>Selecciona un dispositivo</option>
                                {/* Render options based on user's devices */}
                                {userData.map((device) => (
                                    <option key={device.Dispositivo._id} value={device.Dispositivo._id}>
                                        {device.Dispositivo.Nombre}
                                    </option>
                                ))}
                            </select>
                            <button onClick={handleAddDeviceButtonClick}>Agregar nuevo IoT</button>
                        </div>
                    )}
                    {showAddDeviceForm && (
                        <div className="IoTform-container"> {/* Nuevo contenedor para el formulario */}
                            {/* Render add device form */}
                            <form onSubmit={handleFormSubmit}>
                                <label htmlFor="deviceName">Nombre del dispositivo:</label>
                                <input type="text" id="deviceName" name="nombre" onChange={handleChange} required />
                                <label htmlFor="deviceKey">Clave de vinculación:</label>
                                <input type="number" id="deviceKey" name="clave" onChange={handleChange} required pattern="^[0-9]+$" />
                                <button type="submit">Registrar dispositivo</button>
                                <button onClick={handleCancelAddDevice}>Cancelar</button>
                            </form>
                        </div>
                    )}
                </div>
            </div>

            {/* Render device details based on selectedDevice */}
            {selectedDevice && (
                <div className="device-details-container">
                    <h1>Dispositivo: {selectedDevice.Nombre}</h1>
                    {/* Render device details based on selectedDevice */}
                    <h3>Señales tomadas por su dispositivo</h3>
                    <div className='toast-container'>
                        <ToastContainer
                            autoClose={2000}
                            hideProgressBar={false}
                            newestOnTop
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            style={{
                                width: isMobile ? "90%" : "35%", // Ancho responsivo según si es móvil o no
                                fontSize: "1.2rem", // Tamaño de fuente responsivo
                                top: isMobile ? "15%" : "23%", // Posición desde arriba responsiva
                                left: isMobile ? "50%" : "82%", // Posición desde la izquierda (centrado horizontalmente)
                                transform: isMobile ? "translateX(-50%)" : "translate(-50%, -50%)" // Centrado horizontal y vertical si es móvil
                            }}
                            closeButton={
                                <button
                                    className="toast-close-button-custom"
                                    style={{ width: 50, height: 50, borderRadius: 5 }}
                                >
                                    X
                                </button>
                            }
                        />
                    </div>
                    <div className="IoTdata-container">
                        <div className="IoTCont">
                            <div className="IoTdata-item">
                                <FontAwesomeIcon icon={faThermometerHalf} className="IoTicon" style={{ color: calculateTemperatureColor(selectedDevice.DatosSensores?.last_temp || 0) }} />
                                <p className='pT'>Temperatura: {selectedDevice.DatosSensores?.last_temp} °C</p>
                            </div>
                            <div className="IoTdata-item">
                                <FontAwesomeIcon icon={faTint} className="IoTicon" style={{ color: calculateHumidityColor(selectedDevice.DatosSensores?.last_hum || 0) }} />
                                <p className='ph'>Humedad: {selectedDevice.DatosSensores?.last_hum} %</p>
                            </div>
                            <div className="IoTdata-item">
                                <img src={isLocked ? ventanaCerradaImg : ventanaAbiertaImg} alt={isLocked ? "Ventana cerrada" : "Ventana abierta"} className="IoTicon" />
                                <p>{lastStateMessage} {selectedDevice?.Estado?.seguro}</p>
                            </div>
                        </div>
                    </div>
                    <div className="IoTaction-buttons">
                        <button className='action' onClick={handleOpenWindow}>Abrir ventana</button>
                        <button className='action' onClick={handleCloseWindow}>Cerrar ventana</button>
                    </div>
                    <h2>Genera un reporte de los estados de tu SmartWindow</h2>
                    <div className='IoTReport'>
                        <form>
                            <label htmlFor="date">Selecciona una fecha:</label>
                            <input type="date" id="date" name="date" value={selectedDate} onChange={handleDateChange} />
                        </form>
                        <button className='IoTreport-button' onClick={generarPDF}><FontAwesomeIcon icon={faFilePdf} className="IoTiconPDF" /> Generar</button>
                    </div>
                </div>
            )}
        </section>
    );


}

export default MyIoT;
