import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import logo from '../assets/LOGO.png';
import { cerrarSesion } from '../Public/SessionService';

function UserHeader() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [cats, setCats ] = useState([]);

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = async () => {
        fetch("https://backend-5uyg.onrender.com/api/cats")
            .then((response) => response.json())
            .then((data) => {
                if (data && data.length > 0) {
                    setCats(data);
                } else {
                    throw new Error("No se encontraron categorías");
                }
            })
            .catch((error) => console.error("Error al cargar las categorías:", error));
    }

    const handleLogout = () => {
        // Limpiar el almacenamiento local al cerrar sesión
        localStorage.removeItem('userInfo');
        cerrarSesion();
        // Redirigir al usuario a la página de inicio de sesión
        window.location.href = '/';
    };

    return (
        <header className="App-header">
            <div className="menu">
                <div className="menu-right">
                    <a href="/user"><img src={logo} className="App-logo" alt="logo"/></a>
                </div>
                <div className="menu-center">PAPER PLUS</div>
                <div className="menu-left">
                    {isMobile ? (
                        <div>
                            <div className={`menu-left ${isMenuOpen ? 'active' : 'inactive'}`} onClick={toggleMenu}>
                                <i className="fas fa-bars"></i> {/* Use Font Awesome icon */}
                                {isMenuOpen && (
                                    <div className="sub-menu">
                                        <a className="menu-button" href="/user/Products">Catálogo General</a>
                                        {cats.map((cat, index) => (
                                            <Link key={index} to={`/user/Products/Category/${cat.Nombre}`} className="menu-button">
                                                {cat.Nombre}
                                            </Link>
                                        ))}
                                        <a className="menu-button" href="/user/MyIoT">Mi IoT</a>
                                        <a className="menu-button" href="/user/Profile">Mi Perfil</a>
                                        <a className="menu-button" href="/" onClick={handleLogout}>Cerrar Sesión</a>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div>
                                <a className="menu-button" href="/" onClick={handleLogout}>Cerrar Sesión</a>
                            </div>
                            <div className={`menu-left ${isMenuOpen ? 'active' : 'inactive'}`} onClick={toggleMenu}>
                                <i className="fas fa-bars"></i> {/* Use Font Awesome icon */}
                                {isMenuOpen && (
                                    <div className="sub-menu">
                                        <a className="menu-button" href="/user/Products">Catálogo General</a>
                                        {cats.map((cat, index) => (
                                            <Link key={index} to={`/user/Products/Category/${cat.Nombre}`} className="menu-button">
                                                {cat.Nombre}
                                            </Link>
                                        ))}
                                        <a className="menu-button" href="/user/MyIoT">Mi IoT</a>
                                        <a className="menu-button" href="/user/Profile">Mi Perfil</a>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={`overlay ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}></div> {/* Add overlay for menu */}
        </header>
    );
}

export default UserHeader;
