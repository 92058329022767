import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Importa Link de react-router-dom
import '../CSS/Prods.css';

function Prods() {
    const [productos, setProductos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [productosPerPage] = useState(8);

    useEffect(() => {
        const interval = setInterval(() => {
            fetch('https://backend-5uyg.onrender.com/api/prods')
                .then(response => response.json())
                .then(data => {
                    if (data && data.length > 0) {
                        setProductos(data);
                    } else {
                        throw new Error("Los datos no contienen las políticas de privacidad");
                    }
                })
                .catch(error => console.error(error));
        }, 500);
        return () => clearInterval(interval);
    }, []);

    // Calcular los índices de los productos a mostrar en la página actual
    const indexOfLastProduct = currentPage * productosPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productosPerPage;
    const currentProducts = productos.slice(indexOfFirstProduct, indexOfLastProduct);

    // Cambiar a la página siguiente
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const handleDetails = (prod) => {
        // Guardar la información completa del producto en localStorage
        localStorage.setItem('prodInfo', JSON.stringify(prod));
    }

    if (!productos) {
        return <></>
    }

    return (
        <section className="Welcome-section">
            <h2>Nuestro catálogo de productos</h2>
            <div className='main-cont'>
                {currentProducts.map((prod, index) => (
                    <div key={index} className="card">
                        <img className='mainImg' src={prod.imagen[0].url} alt={prod.Nombre}></img>
                        <p className='title'>{prod.Nombre}</p>
                        <p className='text'>Precio: {prod.Precio}</p>
                        <p className='text'>Stock: {prod.StockActual}</p>
                        <div>
                            <Link to={`/ProductDetails/${prod.Nombre.replace(/\s+/g, '-').toLowerCase()}`}>
                                <button className="det" onClick={() => handleDetails(prod)}>Detalles</button>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
            {/* Paginación */}
            <ul className="pagination">
                {Array.from({ length: Math.ceil(productos.length / productosPerPage) }).map((_, index) => (
                    <li key={index} className="page-item">
                        <button onClick={() => paginate(index + 1)} className="page-link">
                            {index + 1}
                        </button>
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default Prods;
