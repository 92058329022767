import React from 'react';
import { useLocation } from 'react-router-dom';
import AdminHeader from '../Admin/AdminHeader';
import AdminFooter from '../Admin/AdminFooter';
import UserHeader from '../Private/UserHeader';
import UserFooter from '../Private/UserFooter';
import Header from './PublicHeader';
import Footer from './PublicFooter';

const LayoutConEncabezado = ({ children }) => {
  const location = useLocation();
  let encabezado;
  let pieDePagina;

 
  if (location.pathname.startsWith('/admin')) {
    encabezado = <AdminHeader />;
    pieDePagina = <AdminFooter />;
  } else if (location.pathname.startsWith('/user')) {
    encabezado = <UserHeader />;
    pieDePagina = <UserFooter />;
  } else {
    encabezado = <Header />;
    pieDePagina = <Footer />;
  }

  return (
    <>
      {encabezado}
      {children}
      {pieDePagina}
    </>
  );
};

export default LayoutConEncabezado;
