import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../CSS/AboutUs.css'

function WelcomeSection() {

  const [img, setImg] = useState([]);

  useEffect(() => {
    fetch('https://backend-5uyg.onrender.com/api/policy')
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0 && data[0].visión) {
          setImg(data[0].img);
        }
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <section className="Welcome-section">
      <h2>Bienvenido a nuestra tienda en línea</h2>
      <div className="carousel-container">
        <Carousel showArrows={false} showThumbs={false} autoPlay={true} interval={2000} infiniteLoop={true}>
          {img.map((image, index) => (
            <div key={index}>
              <img src={image.url} alt={`Slide ${index}`} style={{
                width: '500px', // Ajusta el ancho según tu diseño
                height: '300px', // Ajusta la altura según tu diseño
              }} />
            </div>
          ))}
        </Carousel>
      </div>
      <p>Observa nuestros productos disponibles para ti</p>
    </section>
  );
}

export default WelcomeSection;