import React, { useEffect, useState } from 'react';
import "./App.css";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import RutaPrivada from "./Components/Views/Public/OAuthContext";
import WelcomeSection from "./Components/Views/Public/PrincipalView";
import PrivacyPolicy from "./Components/Views/Public/PrivacyPolicy";
import AboutUs from "./Components/Views/Public/AboutUs";
import FAQ from "./Components/Views/Public/FAQ";
import Prods from "./Components/Views/Public/Products";
import LogIn from "./Components/Views/Public/LogIn";
import SignUp from "./Components/Views/Public/SignUp";
import UserWelcome from "./Components/Views/Private/UserWelcome";
import MyIoT from "./Components/Views/Private/UserIoT";
import UserProfile from "./Components/Views/Private/UserProfile";
import ProductDetails from "./Components/Views/Public/ProductDetail";
import ProdCats from "./Components/Views/Public/ProdCats";
import AdminWelcome from "./Components/Views/Admin/AdminWelcome";
import ProductCrud from "./Components/Views/Admin/ProductsCrud";
import BusinessInfo from "./Components/Views/Admin/InfoManagement";
import UsersCrud from "./Components/Views/Admin/UsersManagement";
import ProvsCrud from "./Components/Views/Admin/ProvsManagement";
import IoTCrud from "./Components/Views/Admin/IoTAssigment";
import FAQsInfo from "./Components/Views/Admin/FAQs";
import PoliciesInfo from "./Components/Views/Admin/Policies";
import LayoutConEncabezado from "./Components/Views/Public/LayoutConEncabezado";
import ContactForm from "./Components/Views/Public/ContactForm";

import { obtenerTipoUsuario } from './Components/Views/Public/SessionService';
import PsswdRecover from './Components/Views/Public/PasswordRecovery';

function App() {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedUser = obtenerTipoUsuario();
    if (storedUser) {
      const redirectPath = storedUser === 'admin' ? '/admin' : '/user';
      // Agregar una condición para verificar si ya está en la ruta de redirección deseada
      if (location.pathname === '/') {
        navigate(redirectPath);
      }
    }
  }, [location.pathname]);


  return (
    <LayoutConEncabezado>

      <Routes>
        <Route path="/" element={<WelcomeSection />} />
        <Route path="/policies" element={<PrivacyPolicy />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/Products" element={<Prods />} />
        <Route path="/Products/Category/:catName" element={<ProdCats />} />
        <Route path="/ProductDetails/:productName" element={<ProductDetails />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/LogIn" element={<LogIn />} />
        <Route path="/RecoverPassword" element={<PsswdRecover />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Feedback" element={<ContactForm />} />

        {/* USER ROUTES*/}
        <Route path="/user" element={<RutaPrivada rolesPermitidos={['user']}> <UserWelcome /> </RutaPrivada>} />
        <Route path="/user/Products" element={<RutaPrivada rolesPermitidos={['user']}> <Prods /> </RutaPrivada>} />
        <Route path="/user/Products/Category/:catName" element={<RutaPrivada rolesPermitidos={['user']}> <ProdCats /> </RutaPrivada>} />
        <Route path="/user/ProductDetails/:productName" element={<RutaPrivada rolesPermitidos={['user']}> <ProductDetails /> </RutaPrivada>} />
        <Route path="/user/MyioT" element={<RutaPrivada rolesPermitidos={['user']}> <MyIoT /> </RutaPrivada>} />
        <Route path="/user/Profile" element={<RutaPrivada rolesPermitidos={['user']}> <UserProfile /> </RutaPrivada>} />
        <Route path="/user/Policies" element={<RutaPrivada rolesPermitidos={['user']}> <PrivacyPolicy /> </RutaPrivada>} />
        <Route path="/user/FAQs" element={<RutaPrivada rolesPermitidos={['user']}> <FAQ /> </RutaPrivada>} />
        <Route path="/user/AboutUs" element={<RutaPrivada rolesPermitidos={['user']}> <AboutUs /> </RutaPrivada>} />
        <Route path="/user/Feedback" element={<RutaPrivada rolesPermitidos={['user']}> <ContactForm /> </RutaPrivada>} />

        {/* ADMIN ROUTES*/}

        <Route path="/admin" element={<RutaPrivada rolesPermitidos={['admin']}> <AdminWelcome /> </RutaPrivada>} />
        <Route path="/admin/ProductsManagement" element={<RutaPrivada rolesPermitidos={['admin']}> <ProductCrud /> </RutaPrivada>} />
        <Route path="/admin/InfoManagement" element={<RutaPrivada rolesPermitidos={['admin']}> <BusinessInfo /> </RutaPrivada>} />
        <Route path="/admin/FAQS" element={<RutaPrivada rolesPermitidos={['admin']}> <FAQsInfo /> </RutaPrivada>} />
        <Route path="/admin/PoliciesInfo" element={<RutaPrivada rolesPermitidos={['admin']}> <PoliciesInfo /> </RutaPrivada>} />
        <Route path="/admin/IoTManagement" element={<RutaPrivada rolesPermitidos={['admin']}> <IoTCrud /> </RutaPrivada>} />
        <Route path="/admin/UsersManagement" element={<RutaPrivada rolesPermitidos={['admin']}> <UsersCrud /> </RutaPrivada>} />
        <Route path="/admin/ProvsManagement" element={<RutaPrivada rolesPermitidos={['admin']}> <ProvsCrud /> </RutaPrivada>} />
        <Route path="/admin/Reports" element={<RutaPrivada rolesPermitidos={['admin']}> <BusinessInfo /> </RutaPrivada>} />
        <Route path="/admin/Policies" element={<RutaPrivada rolesPermitidos={['admin']}> <PrivacyPolicy /> </RutaPrivada>} />
        <Route path="/admin/AboutUs" element={<RutaPrivada rolesPermitidos={['admin']}> <AboutUs /> </RutaPrivada>} />
        <Route path="/admin/Feedback" element={<RutaPrivada rolesPermitidos={['user']}> <ContactForm /> </RutaPrivada>} />
        <Route path="/admin/FAQs" element={<RutaPrivada rolesPermitidos={['user']}> <FAQ /> </RutaPrivada>} />
      </Routes>
    </LayoutConEncabezado>
  );
}

export default App;
