import React, { useState, useEffect } from 'react';
import MapComponent from './mapAPI';

function Footer() {

  const [dir, setDir] = useState([]);

  useEffect(() => {
    fetch('https://backend-5uyg.onrender.com/api/policy')
      .then(response => response.json())
      .then(data => {
        if (data && data.length > 0 && data[0].visión) {
          setDir(data[0].contacto.direccion);
        } else {
          throw new Error("Los datos no contienen las políticas de privacidad");
        }
      })
      .catch(error => console.error("Error al cargar las políticas:", error));
  }, [dir]);

  return (
    <footer className="App-footer">
      <div className="footer-left">
        <h3>Acerca de</h3>
        <a href="/Feedback">Contacto</a>
        <a href="/policies">Políticas</a>
        <a href="/faq">FAQ</a>
        <a href="/AboutUs">¿Quiénes Somos?</a>
      </div>
      <div className="footer-center">
        <h3>Nuestras Redes Sociales</h3>
        <a href="https://www.instagram.com/"><i className="fab fa-instagram"> </i>&nbsp;&nbsp;&nbsp;@paperplus</a>
        <a href="https://www.facebook.com/"><i className="fab fa-facebook-f"> </i>&nbsp;&nbsp;&nbsp;@paperplus</a>
        <a href="https://twitter.com/"><i className="fab fa-twitter"> </i>&nbsp;&nbsp;&nbsp;@paperplus</a>
      </div>
      <div className="footer-right">
        <h3>Ubicación</h3>
        <p>{dir}</p>
        <MapComponent />
      </div>
    </footer>
  );
}

export default Footer;
