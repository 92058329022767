import React, { useState, useEffect } from "react";
import "../CSS/ProductCrud.css";
import axios from "axios"; // Import axios

const API_URL = "https://backend-5uyg.onrender.com/api/addProv";

function ProvsCrud() {
    const [provs, setProvs] = useState([]);
    const [editedProvIndex, setEditedProvIndex] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [formData, setFormData] = useState({
        Nombre: '',
        Telefono: '',
        Correo: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch("https://backend-5uyg.onrender.com/api/provs");
            const data = await response.json();
            setProvs(data);
        } catch (error) {
            console.error("Error al cargar los usuarios:", error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(API_URL, formData);
            if (response.status === 200) {
                fetchData();
                setFormData({
                    Nombre: '',
                    Telefono: '',
                    Correo: '',
                });
            } else {
                console.error("Error al crear el Prov:", response.data);
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
    };


    const handleDeleteProv = async (nombre) => {
        try {
            const response = await axios.delete(`https://backend-5uyg.onrender.com/api/prov/${nombre}`);
            if (response.status === 200) {
                fetchData();
                console.log("Proveedor elminado correctamente:", response.data);
            } else {
                console.error("Error al elminar el proveedor:", response.data);
            }
            // Actualizar la lista de dispositivos después de eliminar el dispositivo
            fetchData();
        } catch (error) {
            console.error("Error al eliminar el dispositivo:", error);
        }
    };

    const handleEditProv = (index) => {
        setEditedProvIndex(index);
        const userToEdit = provs[index];
        setFormData(userToEdit);
    };


    const handleSaveProv = async (nombre) => {
        try {
            const response = await axios.post(`https://backend-5uyg.onrender.com/api/prov/${nombre}`, formData);
            if (response.status === 200) {
                fetchData();
                console.log("Dispositivo actualizado correctamente:", response.data);
            } else {
                console.log(formData)
                console.error("Error al crear el dispositivo:", response.data);
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
        setEditedProvIndex(null);
        setFormData({
            Nombre: '',
            Telefono: '',
            Correo: '',
        });
    };

    return (
        <div className="ProductCrud-container">
            {showForm ? (
                <section className="Form-section">
                    <h2>Agregar Proveedor</h2>
                    <form onSubmit={handleSubmit} className="form">
                        <label htmlFor="employeeFirstName">Nombre:</label>
                        <input
                            type="text"
                            id="employeeFirstName"
                            name="Nombre"
                            value={formData.Nombre}
                            onChange={handleChange}
                        />
                        <label htmlFor="employeePhone">Teléfono:</label>
                        <input
                            type="number"
                            id="employeePhone"
                            name="Telefono"
                            value={formData.Telefono}
                            onChange={handleChange}
                        />
                        <label htmlFor="employeeEmail">Correo:</label>
                        <input
                            type="email"
                            id="employeeEmail"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                        />
                        <button type="submit">Registrar</button>
                        <button onClick={() => setShowForm(false)}>Cancelar</button>
                    </form>
                </section>
            ) : (
                <button className="btn" onClick={() => setShowForm(true)}>Agregar Nuevo</button>
            )}
            <section className="Table-section">
                <h2>Lista de Proveedores</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>Correo</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {provs.map((proveedor, index) => (
                            <tr key={proveedor._id}>
                                <td>
                                    {editedProvIndex === index ? (
                                        <input
                                            type="text"
                                            name="Nombre"
                                            value={formData.Nombre}
                                            onChange={(e) => handleChange(e, "nombre")}
                                        />
                                    ) : (
                                        <span>{proveedor.Nombre}</span>
                                    )}
                                </td>
                                <td>
                                    {editedProvIndex === index ? (
                                        <input
                                            type="text"
                                            name="Telefono"
                                            value={formData.Telefono}
                                            onChange={(e) => handleChange(e, "telefono")}
                                        />
                                    ) : (
                                        <span>{proveedor.Telefono}</span>
                                    )}
                                </td>
                                <td>
                                    {editedProvIndex === index ? (
                                        <input
                                            type="text"
                                            name="Email"
                                            value={formData.Email}
                                            onChange={(e) => handleChange(e, "correo")}
                                        />
                                    ) : (
                                        <span>{proveedor.Email}</span>
                                    )}
                                </td>
                                <td>
                                    {editedProvIndex === index ? (
                                        <div>
                                            <button onClick={() => handleSaveProv(proveedor._id)}>Guardar</button>
                                            <button onClick={() => setEditedProvIndex(null)}>Cancelar</button>
                                        </div>
                                    ) : (
                                        <div>
                                            <button onClick={() => handleEditProv(index)}>Editar</button>
                                            <button onClick={() => handleDeleteProv(proveedor.Nombre)}>Eliminar</button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div>
    );
}

export default ProvsCrud;
