import React from 'react';
import '../CSS/AboutUs.css'
import Goals from './Goals';
import Mission from './Mission';
import Vision from './Vision';

function AboutUs(){

    return (
        <section className="Welcome-section">
            <h2>¿Quién Somos?</h2>
            <div className='main'>
                <div className="central-div">
                    <Goals/>
                </div>
                <div className="central-div">
                    <Mission/>
                </div>
                <div className="central-div">
                    <Vision/>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;

