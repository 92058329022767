import React, { useEffect } from 'react';
import L from 'leaflet';

function MapComponent() {
  /**useEffect(() => {
    const mapContainer = document.getElementById('map-container');

    const map = L.map(mapContainer).setView([21.171614, -98.590285], 17);

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 50,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(map);

    const marker = L.marker([21.171614, -98.590285]).addTo(map);

    marker.bindPopup("<b>3400 Warner Blvd, Burbank, CA 91505, EE. UU.</b>").openPopup();

    // Cleanup function (optional for performance optimization)
    return () => {
      map.remove();
    };
  }, []);*/

  return (
    <div id="map-container">
      <div id="map">
      <iframe width="425" height="350" src="https://www.openstreetmap.org/export/embed.html?bbox=-98.59495639801027%2C21.16524323088958%2C-98.57856273651124%2C21.176868701443638&amp;layer=mapnik&amp;marker=21.171066085069544%2C-98.58675956726074"></iframe><br/><small><a href="https://www.openstreetmap.org/?mlat=21.1711&amp;mlon=-98.5868#map=16/21.1711/-98.5868&amp;layers=N">Ver el mapa más grande</a></small>
      </div>
    </div>
  );
}

export default MapComponent;
