import MapComponent from "../Public/mapAPI";

function AdminFooter() {

  return (
    <footer className="App-footer">
      <div className="footer-left">
        <h3>Nuestra Iformación</h3>
        <a href="/admin/">Contacto</a>
        <a href="/admin/Policies">Políticas</a>
        <a href="/admin/FAQs">FAQ</a>
        <a href="/admin/AboutUs">¿Quiénes Somos?</a>
      </div>
      <div className="footer-center">
        <h3>Nuestras Redes Sociales</h3>
        <a href="https://www.instagram.com/"><i className="fab fa-instagram"> </i>&nbsp;&nbsp;&nbsp;@paperplus</a>
        <a href="https://www.facebook.com/"><i className="fab fa-facebook-f"> </i>&nbsp;&nbsp;&nbsp;@paperplus</a>
        <a href="https://twitter.com/"><i className="fab fa-twitter"> </i>&nbsp;&nbsp;&nbsp;@paperplus</a>
      </div>
      <div className="footer-right">
        <h3>Ubicación</h3>
        <MapComponent />
      </div>
    </footer>
  );
}

export default AdminFooter;
