import React, { useState, useEffect } from 'react';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import "../CSS/Profile.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function UserProfile() {
    const [userData, setUserData] = useState(null);
    const [qData, setQData] = useState(null);
    const [editableFields, setEditableFields] = useState(null);
    const [editing, setEditing] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCon, setShowPasswordCon] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            const user = JSON.parse(localStorage.getItem('userInfo'));
            if (user && user._id && user.name) {
                fetchData(user._id);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const fetchData = async (userId) => {
        try {
            const userResponse = await fetch(`https://backend-5uyg.onrender.com/api/user/${userId}`);
            const userData = await userResponse.json();

            if (userData && userData.length > 0) {
                setUserData(userData[0]);
                setEditableFields({
                    Email: userData[0].Email,
                    Telefono: userData[0].Telefono,
                    Respuesta: userData[0].Respuesta
                });
            }

            const idP = userData[0].IdPregunta;
            const secretQResponse = await fetch(`https://backend-5uyg.onrender.com/api/secretQU/${idP}`);
            const secretQData = await secretQResponse.json();

            if (secretQData && secretQData.length > 0) {
                setQData(secretQData[0]);
            }
        } catch (error) {
            toast.error("Error al cargar los datos");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditableFields(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleEdit = () => {
        setEditMode(true);
        setEditing(true);
    };

    const handleCancel = () => {
        setEditing(false);
        setEditMode(false);
        setEditableFields({
            Email: userData.Email,
            Telefono: userData.Telefono,
            Respuesta: userData.Respuesta
        });
        setNewPassword('');
    };

    const handleSave = async (e) => {
        e.preventDefault();
        if (!editableFields.Email || !editableFields.Telefono || !editableFields.Respuesta) {
            toast.warning("Todos los campos son obligatorios");
            return;
        }
        // Validar el formato del correo electrónico
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(editableFields.Email)) {
            toast.warning('Por favor, ingresa un correo electrónico válido.');
            return;
        }
        // Validar el formato del teléfono (10 números)
        if (!/^\d{10}$/.test(editableFields.Telefono)) {
            toast.warning('El teléfono debe contener 10 números.');
            return;
        }
        try {
            const response = await axios.post(`https://backend-5uyg.onrender.com/api/userData/${userData._id}`, editableFields);
            if (response.status === 200) {
                toast.success("Datos actualizados con éxito");
                setEditing(false);
                setEditMode(false);
            } else {
                toast.error("Error al actualizar los datos");
            }
        } catch (error) {
            toast.error("Error en la solicitud");
        }
    };

    const handlePasswordChange = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`https://backend-5uyg.onrender.com/api/userPsswd/${userData._id}`, {
                oldPassword,
                newPassword
            });
            if (response.status === 200) {
                toast.success("Contraseña actualizada con éxito");
                setOldPassword('');
                setNewPassword('');
            } else {
                toast.error("Error al actualizar la contraseña");
            }
        } catch (error) {
            toast.error("Error en la solicitud");
        }
    };


    if (!userData) {
        return (
            <div className="loading-spinner">
                <h1>Cargando datos...</h1>
                <div className="spinner"></div>
            </div>
        );
    }


    return (
        <div className="user-profile-container">
            <div className="user-profile-section">
                <h1 className="user-profile-section-title">Información Personal</h1>
                <p>Nombre: {userData.Nombre}</p>
                <p>Apellido Paterno: {userData.Apaterno}</p>
                <p>Apellido Materno: {userData.Amaterno}</p>
            </div>
            <div className='toast-container'>
                <ToastContainer
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{
                        width: isMobile ? "90%" : "35%", // Ancho responsivo según si es móvil o no
                        fontSize: "1.2rem", // Tamaño de fuente responsivo
                        top: isMobile ? "15%" : "23%", // Posición desde arriba responsiva
                        left: isMobile ? "50%" : "82%", // Posición desde la izquierda (centrado horizontalmente)
                        transform: isMobile ? "translateX(-50%)" : "translate(-50%, -50%)" // Centrado horizontal y vertical si es móvil
                    }}
                    closeButton={
                        <button
                            className="toast-close-button-custom"
                            style={{ width: 50, height: 50, borderRadius: 5 }}
                        >
                            X
                        </button>
                    }
                />
            </div>
            <div className="user-profile-section">
                <h1 className="user-profile-section-title">Información de Contacto</h1>
                <form onSubmit={handleSave}>
                    <label>
                        Email: { }
                        {editing ? (
                            <input
                                type="email"
                                name="Email"
                                value={editableFields.Email}
                                onChange={handleChange}
                                className="user-profile-input"
                            />
                        ) : (
                            userData.Email
                        )}
                    </label>
                    <label>
                        Teléfono: { }
                        {editing ? (
                            <input
                                type="tel"
                                name="Telefono"
                                value={editableFields.Telefono}
                                onChange={handleChange}
                                className="user-profile-input"
                            />
                        ) : (
                            userData.Telefono
                        )}
                    </label>
                    <label>Pregunta {qData?.pregunta}</label>
                    <label>
                        Respuesta a la pregunta secreta: { }
                        {editing ? (
                            <input
                                type="text"
                                name="Respuesta"
                                value={editableFields.Respuesta}
                                onChange={handleChange}
                                className="user-profile-input"
                            />
                        ) : (
                            userData.Respuesta
                        )}
                    </label>

                    {editMode && (
                        <button type="submit" className="user-profile-button">
                            <FontAwesomeIcon icon={faSave} /> Guardar
                        </button>
                    )}

                    {editing && (
                        <button type="button" onClick={handleCancel} className="user-profile-button">
                            <FontAwesomeIcon icon={faTimes} /> Cancelar
                        </button>
                    )}

                    {!editing && (
                        <button onClick={handleEdit} className="user-profile-button">
                            <FontAwesomeIcon icon={faEdit} /> Editar
                        </button>
                    )}
                </form>
            </div>

            <div className="user-profile-section">
                <h1 className="user-profile-section-title">Cambiar Contraseña</h1>
                <form onSubmit={handlePasswordChange}>
                    <label> Contraseña Anterior: </label>
                    <div className="password-input-container">
                        <input
                            type={showPassword ? "text" : "password"}
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            className="user-profile-input"
                        />
                        <button type="button" onClick={() => setShowPassword(!showPassword)}>
                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    <label> Nueva Contraseña:</label>
                    <div className="password-input-container">
                        <input
                            type={showPasswordCon ? "text" : "password"}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="user-profile-input"
                        />
                        <button type="button" onClick={() => setShowPasswordCon(!showPasswordCon)}>
                            <FontAwesomeIcon icon={showPasswordCon ? faEyeSlash : faEye} />
                        </button>
                    </div>
                    <button type="submit" className="user-profile-button">
                        <FontAwesomeIcon icon={faSave} /> Guardar Contraseña
                    </button>
                </form>

            </div >
        </div >
    );
}

export default UserProfile;
