import React, { useState, useEffect } from 'react';

function PrivacyPolicy() {
    const [politicas, setPoliticas] = useState([]);

    useEffect(() => {
        fetch('https://backend-5uyg.onrender.com/api/policy')
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0 && data[0].politicas) {
                    setPoliticas(data[0].politicas);
                } else {
                    throw new Error("Los datos no contienen las políticas de privacidad");
                }
            })
            .catch(error => console.error("Error al cargar las políticas:", error));
    }, []);

    return (
        <section className="Welcome-section">
            <h2>Nuestra Política de Privacidad</h2>
            {politicas.map((politica, index) => (
                <div key={index}>
                    <h3>{politica.titulo}</h3>
                    <p>{politica.contenido}</p>
                </div>
            ))}
        </section>
    );
}

export default PrivacyPolicy;
