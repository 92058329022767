import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../CSS/BussinesInfo.css"

function EditableField({ label, value, onSave }) {
    const [mode, setMode] = useState('view');
    const [fieldValue, setFieldValue] = useState(value);

    useEffect(() => {
        setFieldValue(value);
    }, [value]); // Actualiza fieldValue cuando el prop value cambia

    const toggleEditMode = () => {
        setMode(mode === 'view' ? 'edit' : 'view');
    };

    const handleSave = () => {
        onSave(fieldValue); // Aquí se guarda el valor solo cuando se hace clic en "Guardar"
        setMode('view');
    };

    const handleCancel = () => {
        setFieldValue(value);
        setMode('view');
    };

    return (
        <div className="info-item">
            <h3>{label}</h3>
            {mode === 'view' ? (
                <p>{value}</p>
            ) : (
                <textarea
                    value={fieldValue}
                    onChange={(e) => setFieldValue(e.target.value)}
                />
            )}
            {mode === 'view' ? (
                <button onClick={toggleEditMode}>Editar</button>
            ) : (
                <>
                    <button onClick={handleSave}>Guardar</button>
                    <button onClick={handleCancel}>Cancelar</button>
                </>
            )}
        </div>
    );
}

function BusinessInfo() {
    const [infoEmp, setInfoEmp] = useState({});

    useEffect(() => {
        fetch('https://backend-5uyg.onrender.com/api/policy')
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0) {
                    setInfoEmp(data[0]);
                } else {
                    throw new Error("Los datos no contienen las políticas de privacidad");
                }
            })
            .catch(error => console.error("Error al cargar las políticas:", error));
    }, [infoEmp]);

    const saveField = async (key, value) => {
        try {
            const response = await axios.post(`https://backend-5uyg.onrender.com/api/info/${key}`, { value });
            console.log("Campo guardado exitosamente:", response.data);
        } catch (error) {
            console.error("Error al guardar el campo:", error);
        }
    };

    return (
        <section className="BusinessInfo-section">
            <h2>Información Empresarial</h2>
            <div className="info-container">
                <EditableField
                    label="Objetivos"
                    value={infoEmp.objetivo || ''}
                    onSave={(value) => saveField('objetivo', value)}
                />
                <EditableField
                    label="Misión"
                    value={infoEmp.misión || ''}
                    onSave={(value) => saveField('misión', value)}
                />
                <EditableField
                    label="Visión"
                    value={infoEmp.visión || ''}
                    onSave={(value) => saveField('visión', value)}
                />
            </div>
        </section>
    );
}

export default BusinessInfo;
