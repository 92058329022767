import React, { useState, useEffect } from 'react';

function FAQ() {
    const [politicas, setPoliticas] = useState([]);
    const [expandedIndex, setExpandedIndex] = useState(null);

    useEffect(() => {
        fetch('https://backend-5uyg.onrender.com/api/policy')
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0 && data[0].faq) {
                    setPoliticas(data[0].faq);
                }
            })
            .catch(error => console.error(error));
    }, []);

    // Función para manejar el clic en la pregunta
    const handleQuestionClick = index => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <section className="faqs">
            <h2>Preguntas Frecuentes (FAQ)</h2>
            {politicas.map((politica, index) => (
                <div key={index} className={expandedIndex === index ? "active" : ""}>
                    <h3 onClick={() => handleQuestionClick(index)}>
                        {politica.pregunta}
                        <span>▾</span>
                    </h3>
                    {expandedIndex === index && <p>{politica.respuesta}</p>}
                </div>
            ))}
        </section>
    );
}

export default FAQ;
