import React, { useState } from 'react';
import '../CSS/Login.css';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function PsswdRecover() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: '',
    });
    const [userInfo, setUserInfo] = useState({
        user: '',
        id: '',
        idP: '',
    });
    const [codigo, setCodigo] = useState('');
    const [respuesta, setRespuesta] = useState('');
    const [isTheCode, setTheCode] = useState(null);
    const [isTheAnswer, setTheAnswer] = useState(null);
    const [qData, setQData] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCon, setShowPasswordCon] = useState(false);

    const handleChangeNewPassword = (e) => {
        const { value } = e.target;
        setNewPassword(value);
    };

    const handleChangeConfirmNewPassword = (e) => {
        const { value } = e.target;
        setConfirmNewPassword(value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleChangeCodigo = (e) => {
        const { value } = e.target;
        setCodigo(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validación de campos
        if (!formData.email.trim()) {
            toast.warning('Por favor, completa todos los campos.');
            return;
        }
        // Validación del formato de correo electrónico usando una expresión regular
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            toast.warning('Por favor, ingresa un correo electrónico válido.');
            return;
        }
        try {
            const response = await axios.get(`https://backend-5uyg.onrender.com/api/userEmail?email=${formData.email}`);
            if (response.status === 200) {
                setUserInfo({
                    user: response.data[0].Usuario,
                    id: response.data[0]._id,
                    idP: response.data[0].IdPregunta,
                });
                toast.success('Usuario encontrado');
            } else if (response.status === 201 || response.status === 202) {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error in the request:", error);
            toast.error('Ocurrió un error. Inténtalo de nuevo');
        }
    };

    const handleSubmitCode = async (e) => {
        e.preventDefault();

        // Expresión regular para validar que el código tenga exactamente 6 dígitos
        const codePattern = /^\d{6}$/;
        if (!codePattern.test(codigo)) {
            toast.warning('El código debe contener exactamente 6 dígitos');
            return;
        }

        // Expresión regular para verificar si el código contiene letras
        const containsLetters = /[a-zA-Z]/.test(codigo);
        if (containsLetters) {
            toast.warning('El código no debe contener letras ni signos');
            return;
        }

        try {
            const response = await axios.get(`https://backend-5uyg.onrender.com/api/verifyCode?codigo=${codigo}`);
            if (response.status === 200) {
                setTheCode(response.data.success);
                fetchData(userInfo.idP)
                toast.success(response.data.message);
            } else if (response.status === 201) {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
            toast.error('Ocurrió un error al verificar el código');
        }
    };

    const fetchData = async (IdP) => {
        try {
            const secretQResponse = await fetch(`https://backend-5uyg.onrender.com/api/secretQU/${IdP}`);
            const secretQData = await secretQResponse.json();
            if (secretQData && secretQData.length > 0) {
                setQData(secretQData[0]);
            }
        } catch (error) {
            console.error("Error al cargar los datos:", error);
        }
    };

    const handleSubmitAnswer = async (e) => {
        e.preventDefault();

        if (!respuesta.trim()) {
            toast.warning('Por favor, ingresa tu respuesta.');
            return;
        }

        try {
            const response = await axios.get(`https://backend-5uyg.onrender.com/api/verifyAnswer/${userInfo.id}`, { params: { answer: respuesta } });
            if (response.status === 200) {
                setTheAnswer(response.data.success);
                toast.success(response.data.message);
            } else if (response.status === 201) {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Ocurrió un error al verificar el código');
        }
    };


    const handleChangeRespuesta = (e) => {
        const { value } = e.target;
        setRespuesta(value);
    };

    const handleSubmitPasswordChange = async (e) => {
        e.preventDefault();

        // Validar que las contraseñas coincidan
        if (newPassword !== confirmNewPassword) {
            toast.error('Las contraseñas no coinciden.');
            return;
        }

        // Validación de contraseña
        if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}/.test(newPassword)) {
            toast.warning('La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas, números y caracteres especiales (.!@#$%^&*()_+).');
            return;
        }

        try {
            const response = await axios.post(`https://backend-5uyg.onrender.com/api/changePassword/${userInfo.id}`, {
                newPassword: newPassword
            });

            if (response.status === 200) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate('/LogIn');
                }, 1000);
            } else if (response.status === 201) {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
            toast.error('Ocurrió un error al cambiar la contraseña');
        }
    };


    return (
        <section className="Login-section">
            <div className="Login-container">
                <div className='toast-container'>
                    <ToastContainer
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        style={{
                            width: isMobile ? "90%" : "35%", // Ancho responsivo según si es móvil o no
                            fontSize: "1.2rem", // Tamaño de fuente responsivo
                            top: isMobile ? "15%" : "23%", // Posición desde arriba responsiva
                            left: isMobile ? "50%" : "82%", // Posición desde la izquierda (centrado horizontalmente)
                            transform: isMobile ? "translateX(-50%)" : "translate(-50%, -50%)" // Centrado horizontal y vertical si es móvil
                        }}
                        closeButton={
                            <button
                                className="toast-close-button-custom"
                                style={{ width: 50, height: 50, borderRadius: 5 }}
                            >
                                X
                            </button>
                        }
                    />
                </div>
                <div className='formCont'>
                    {userInfo.user && !isTheCode ? (
                        <>
                            <h2>Recuperación de contraseña para el usuario {userInfo.user}</h2>
                            <form onSubmit={handleSubmitCode}>
                                <label htmlFor="codigo">Ingresa el código de verificación:</label>
                                <input
                                    type="text"
                                    id="codigo"
                                    name="codigo"
                                    value={codigo}
                                    onChange={handleChangeCodigo}
                                />
                                <button type="submit">Verificar código</button>
                            </form>
                        </>
                    ) : userInfo.user && isTheCode && !isTheAnswer ? (
                        <>
                            <h2>Responder a tu pregunta secreta</h2>
                            <form onSubmit={handleSubmitAnswer}>
                                <label>Paso 2 de 2</label>
                                <label>{qData?.pregunta}</label>
                                <label htmlFor="respuesta">Ingresa tu respuesta:</label>
                                <input
                                    type="text"
                                    id="respuesta"
                                    name="respuesta"
                                    value={respuesta}
                                    onChange={handleChangeRespuesta}
                                />
                                <button type="submit">Enviar respuesta</button>
                            </form>
                        </>
                    ) : isTheAnswer ? (
                        <>
                            <h2>Cambiar Contraseña</h2>
                            <form onSubmit={handleSubmitPasswordChange}>

                                <label>Nueva Contraseña:</label>
                                <div className="password-input-container">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="newPassword"
                                        name="newPassword"
                                        value={newPassword}
                                        onChange={handleChangeNewPassword}
                                    />
                                    <button type="button" onClick={() => setShowPassword(!showPassword)}>
                                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <label>Confirmar Nueva Contraseña:</label>
                                <div className="password-input-container">
                                    <input
                                        type={showPasswordCon ? "text" : "password"}
                                        id="confirmNewPassword"
                                        name="confirmNewPassword"
                                        value={confirmNewPassword}
                                        onChange={handleChangeConfirmNewPassword}
                                    />
                                    <button type="button" onClick={() => setShowPasswordCon(!showPasswordCon)}>
                                        <FontAwesomeIcon icon={showPasswordCon ? faEyeSlash : faEye} />
                                    </button>
                                </div>
                                <button type="submit">Cambiar Contraseña</button>
                            </form>

                        </>
                    ) : (
                        <>
                            <h2>Verificación de 2 pasos</h2>
                            <form onSubmit={handleSubmit}>
                                <label>Paso 1 de 2</label>
                                <label htmlFor="email">Ingresa tu correo electrónico:</label>
                                <input
                                    type="text"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <button type="submit">Enviar código de verificación</button>
                            </form>
                        </>
                    )}
                    <div className="Login-links">
                        <a href="/LogIn">Volver al inicio de sesión</a>
                    </div>
                </div>
            </div>
        </section>
    );


}

export default PsswdRecover;
