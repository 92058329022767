import React, { useState, useEffect } from 'react';
import '../CSS/AboutUs.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function Vision() {
    const [politicas, setPoliticas] = useState([]);

    useEffect(() => {
        fetch('https://backend-5uyg.onrender.com/api/policy')
            .then(response => response.json())
            .then(data => {
                if (data && data.length > 0 && data[0].visión) {
                    setPoliticas(data[0].visión);
                } else {
                    throw new Error("Los datos no contienen las políticas de privacidad");
                }
            })
            .catch(error => console.error("Error al cargar las políticas:", error));
    }, []);

    return (
        <section>
            <h2>Visión</h2>
            <div>
                <FontAwesomeIcon icon={faEye}  className='icon'/>
                <p>{politicas}</p>
            </div>
        </section>
    );
}

export default Vision;
