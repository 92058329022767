import React, { useState, useEffect } from "react";
import "../CSS/ProductCrud.css";
import axios from "axios"; // Import axios

function ProductCrud() {
    const [formData, setFormData] = useState({
        Nombre: '',
        Descripcion: '',
        Precio: '',
        Categoria: '',
        Marca: '',
        imagen: []
    });

    const [products, setProducts] = useState([]);
    const [cats, setCats] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [editedProductIndex, setEditedProductIndex] = useState(null);

    useEffect(() => {
            fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [productsResponse, catsResponse, marcasResponse] = await Promise.all([
                axios.get("https://backend-5uyg.onrender.com/api/prods"),
                axios.get("https://backend-5uyg.onrender.com/api/cats"),
                axios.get("https://backend-5uyg.onrender.com/api/marcas")
            ]);

            setProducts(productsResponse.data);
            setCats(catsResponse.data);
            setMarcas(marcasResponse.data);
        } catch (error) {
            console.error("Error al cargar los datos:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        console.log(formData)
    };

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData(prevData => ({
            ...prevData,
            imagen: files
        }));
        console.log(formData)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Subir las imágenes al servidor y obtener las URL
        const imageUploadPromises = formData.imagen.map(file => {
            if (file) {
                const formData = new FormData();
                formData.append('imagen', file);
                return axios.post('https://backend-5uyg.onrender.com/api/imagenes/upload', formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }).then(response => response.data.url);
            }
            return Promise.resolve('');
        });

        // Esperar a que todas las imágenes se suban y obtener las URL resultantes
        const imageUrls = await Promise.all(imageUploadPromises);

        // Crear un nuevo objeto FormData con las URL de las imágenes
        const newFormData = {
            ...formData,
            imagen: imageUrls.map(url => (url ? { url } : null)).filter(item => item)
        };

        try {
            // Realizar la solicitud POST para crear el producto con las imágenes
            await axios.post(`https://backend-5uyg.onrender.com/api/addProd/${newFormData.Categoria}/${newFormData.Marca}`, newFormData);
            fetchData();
            alert('Producto creado con éxito.');
        } catch (error) {
            console.error('Error al crear el producto:', error);
            alert('Error al crear el producto.');
        }
    };


    const handleDeleteProduct = async (id) => {
        try {
            const response = await axios.delete(`https://backend-5uyg.onrender.com/api/delProd/${id}`);
            if (response.status === 200) {
                fetchData();
                console.log("Producto eliminado correctamente:", response.data);
            } else {
                console.error("Error al eliminar el producto:", response.data);
            }
        } catch (error) {
            console.error("Error al eliminar el producto:", error);
        }
    };

    const handleEditProduct = (index) => {
        setEditedProductIndex(index);
        const productEdit = products[index];
        setFormData(productEdit);
    };

    const handleSaveProduct = async (id) => {
        try {
            const response = await axios.post(`https://backend-5uyg.onrender.com/api/prod/${id}`, formData);
            if (response.status === 200) {
                fetchData();
                setFormData(null)
                console.log("Producto actualizado correctamente:", response.data);
            } else {
                console.error("Error al actualizar el producto:", response.data);
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        }
        setEditedProductIndex(null);
    };

    if (!products) {
        return <></>
    }

    return (
        <div className="ProductCrud-container">
            {showForm ? (
                <section className="Form-section">
                    <h2>Agregar Producto</h2>
                    <form onSubmit={handleSubmit} encType="multipart/form-data" className="form">
                        <label htmlFor="productName">Nombre:</label>
                        <input
                            type="text"
                            id="productName"
                            name="Nombre"
                            value={formData.Nombre}
                            onChange={handleChange}
                        />
                        <label htmlFor="productDescription">Descripción:</label>
                        <textarea
                            id="productDescription"
                            name="Descripcion"
                            value={formData.Descripcion}
                            onChange={handleChange}
                        />
                        <label htmlFor="productPrice">Precio:</label>
                        <input
                            type="number"
                            id="productPrice"
                            name="Precio"
                            value={formData.Precio}
                            onChange={handleChange}
                        />
                        <label htmlFor="productCategory">Categoría:</label>
                        <select
                            id="productCategory"
                            name="Categoria"
                            value={formData.Categoria}
                            onChange={handleChange}
                        >
                            <option value="">Selecciona una categoría</option>
                            {cats.map(cat => (
                                <option key={cat._id} value={cat._id}>{cat.Nombre}</option>
                            ))}
                        </select>
                        <label htmlFor="productBrand">Marca:</label>
                        <select
                            id="productBrand"
                            name="Marca"
                            value={formData.Marca}
                            onChange={handleChange}
                        >
                            <option value="">Selecciona una marca</option>
                            {marcas.map(marca => (
                                <option key={marca._id} value={marca._id}>{marca.Nombre}</option>
                            ))}
                        </select>
                        <label htmlFor="productImages">Imágenes:</label>
                        <input
                            type="file"
                            id="productImages"
                            name="imagen"
                            onChange={handleImageChange}
                            accept="image/jpeg, image/jpg, image/png, image/webp"
                            multiple
                        />
                        <div className="buttons">
                            <button type="submit">Agregar</button>
                            <button onClick={() => setShowForm(false)}>Cancelar</button>
                        </div>
                    </form>
                </section>
            ) : (
                <button className="btn" onClick={() => setShowForm(true)}>Agregar Nuevo</button>
            )}
            <section className="Table-section">
                <h2>Lista de Productos</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Descripción</th>
                            <th>Stock</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map((product, index) => (
                            <tr key={product._id}>
                                <td>
                                    {editedProductIndex === index ? (
                                        <input
                                            type="text"
                                            name="Nombre"
                                            value={formData.Nombre}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <span>{product.Nombre}</span>
                                    )}
                                </td>
                                <td>
                                    {editedProductIndex === index ? (
                                        <input
                                            type="text"
                                            name="Descripcion"
                                            value={formData.Descripcion}
                                            onChange={handleChange}
                                        />
                                    ) : (
                                        <span>{product.Descripcion}</span>
                                    )}
                                </td>
                                <td>{product.StockActual}</td>
                                <td>
                                    {editedProductIndex === index ? (
                                        <div>
                                            <button onClick={() => handleSaveProduct(product._id)}>Guardar</button>
                                            <button onClick={() => setEditedProductIndex(null)}>Cancelar</button>
                                        </div>
                                    ) : (
                                        <div>
                                            <button onClick={() => handleEditProduct(index)}>Editar</button>
                                            <button onClick={() => handleDeleteProduct(product._id)}>Eliminar</button>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </div>
    );
}

export default ProductCrud;