import React, { useState } from 'react';
import '../CSS/Login.css';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { iniciarSesion } from './SessionService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const API_URL = "https://backend-5uyg.onrender.com/api/login";

function LogIn() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    usuario: '',
    password: '',
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación de campos
    if (!formData.usuario.trim() || !formData.password.trim()) {
      toast.warning('Por favor, completa todos los campos.');
      return;
    }

    // Validación de contraseña
    if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}/.test(formData.password)) {
      toast.warning('La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas, números y caracteres especiales (.!@#$%^&*()_+).');
      return;
    }

    try {
      const response = await axios.post(API_URL, formData);
      console.log(response)
      if (response.status === 200) {
        toast.success('¡Inicio de sesión exitoso!');
        let userData;
        if (response.data.userType === 'user') {
          userData = {
            _id: response.data.cliente._id,
            name: response.data.cliente.Nombre,
            userType: 'user'
          };
        } else if (response.data.userType === 'admin') {
          userData = {
            _id: response.data.empleado._id,
            name: response.data.empleado.Nombre,
            userType: 'admin'
          };
        }
        iniciarSesion(userData.userType);
        localStorage.setItem('userInfo', JSON.stringify(userData));
        const redirectPath = response.data.userType === 'admin' ? '/admin' : '/user';
        navigate(redirectPath);
        console.log("Logged in", response.data);
      } else if (response.status === 201) {
        toast.error(response.data.message); // Mostrar mensaje de error del servidor
      } else {
        toast.error('Ocurrió un error. Inténtalo de nuevo'); // Mostrar mensaje de error genérico
      }
    } catch (error) {
      console.error("Error in the request:", error);
      toast.error('Ocurrió un error. Inténtalo de nuevo'); // Mensaje de error genérico
    }
  };

  return (
    <section className="Login-section">
      <div className="Login-container">
        <h2>Iniciar Sesión</h2>
        <div className='toast-container'>
          <ToastContainer
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
              width: isMobile ? "90%" : "35%", // Ancho responsivo según si es móvil o no
              fontSize: "1.2rem", // Tamaño de fuente responsivo
              top: isMobile ? "15%" : "23%", // Posición desde arriba responsiva
              left: isMobile ? "50%" : "82%", // Posición desde la izquierda (centrado horizontalmente)
              transform: isMobile ? "translateX(-50%)" : "translate(-50%, -50%)" // Centrado horizontal y vertical si es móvil
            }}
            closeButton={
              <button
                className="toast-close-button-custom"
                style={{ width: 50, height: 50, borderRadius: 5 }}
              >
                X
              </button>
            }
          />
        </div>
        <div className='formCont'>
          <form onSubmit={handleSubmit}>
            <label htmlFor="usuario">Usuario:</label>
            <input
              type="text"
              id="usuario"
              name="usuario"
              value={formData.usuario}
              onChange={handleChange}
            />
            <label htmlFor="contrasena">Contraseña:</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                id="contrasena"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
              <button type="button" onClick={togglePasswordVisibility}>
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
            <button type="submit">Ingresar</button>
          </form>
          <div className="Login-links">
            <a href="/RecoverPassword">¿Olvidaste tu contraseña?</a>
            O
            <a href="/SignUp">Registráte</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LogIn;
