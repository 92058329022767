import React, { useState, useEffect } from 'react';
import '../CSS/Login.css';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignUp() {
  const [secretQ, setSecretQ] = useState([]);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    Nombre: '',
    Apaterno: '',
    Amaterno: '',
    Usuario: '',
    Telefono: '',
    Email: '',
    IdPregunta: '',
    Respuesta: '',
    Psswd: '',
    PsswdCon: ''
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch("https://backend-5uyg.onrender.com/api/secretQ");
      const data = await response.json();
      setSecretQ(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [step, setStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar campos
    if (!formData.Nombre.trim() || !formData.Apaterno.trim() || !formData.Amaterno.trim()) {
      toast.warning('Por favor, completa todos los campos de Datos Generales.');
      return;
    }

    if (!formData.Usuario.trim() || !formData.Telefono.trim() || !formData.Email.trim() || !formData.IdPregunta.trim() || !formData.Respuesta.trim()) {
      toast.warning('Por favor, completa todos los campos de Datos de Usuario.');
      return;
    }

    if (!formData.Psswd.trim() || !formData.PsswdCon.trim()) {
      toast.warning('Por favor, completa ambos campos de Contraseña.');
      return;
    }

    // Validar patrones de campos
    const namePattern = /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]{2,}$/;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!namePattern.test(formData.Nombre)) {
      toast.warning('Por favor, ingresa un nombre válido.');
      return;
    }

    if (!namePattern.test(formData.Apaterno) || !namePattern.test(formData.Amaterno)) {
      toast.warning('Por favor, ingresa apellidos válidos.');
      return;
    }

    if (!formData.Usuario.match(/^[a-zA-Z0-9]+$/)) {
      toast.warning('El nombre de usuario solo puede contener letras y números.');
      return;
    }

    if (!formData.Telefono.match(/^\d{10}$/)) {
      toast.warning('Por favor, ingresa un número de teléfono válido de 10 dígitos.');
      return;
    }

    if (!emailPattern.test(formData.Email)) {
      toast.warning('Por favor, ingresa un correo electrónico válido.');
      return;
    }

    // Validar contraseña
    if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}/.test(formData.Psswd)) {
      toast.warning('La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas, números y caracteres especiales (.!@#$%^&*()_+).');
      return;
    }

    // Verificar que las contraseñas coincidan
    if (formData.Psswd !== formData.PsswdCon) {
      toast.warning('Las contraseñas no coinciden.');
      return;
    }

    try {
      const response = await axios.post(`https://backend-5uyg.onrender.com/api/addUser/${formData.IdPregunta}`, formData);
      if (response.status === 200) {
        toast.success('¡Registro exitoso! Por favor, inicia sesión.');
        navigate('/LogIn');
        // Limpiar el formulario después del registro exitoso
        setFormData({
          Nombre: '',
          Apaterno: '',
          Amaterno: '',
          Usuario: '',
          Telefono: '',
          Email: '',
          IdPregunta: '',
          Respuesta: '',
          Psswd: '',
          PsswdCon: ''
        });
        setStep(1); // Reiniciar el paso al valor inicial
      } else {
        console.error("Error al Registrar:", response.data);
        toast.error('Ocurrió un error al intentar registrarse. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      console.error("Error en la solicitud:", error);
      toast.error('Ocurrió un error al intentar registrarse. Por favor, inténtalo de nuevo.');
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleNext = () => {
    let requiredFields = [];
    let currentStepFields = [];

    // Definir los campos requeridos para la sección actual
    if (step === 1) {
      requiredFields = ['Nombre', 'Apaterno', 'Amaterno'];
      currentStepFields = requiredFields.filter(field => !formData[field]);
    } else if (step === 2) {
      requiredFields = ['Usuario', 'Telefono', 'Email', 'IdPregunta', 'Respuesta'];
      currentStepFields = requiredFields.filter(field => !formData[field]);

      // Verificar si el teléfono está en el formato correcto
      if (!formData.Telefono.match(/^\d{10}$/)) {
        toast.warning('Por favor, ingresa un número de teléfono válido de 10 dígitos.');
        return;
      }

      // Verificar si el correo electrónico está en el formato correcto
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.Email)) {
        toast.warning('Por favor, ingresa un correo electrónico válido. Ejemplo: nombre@gmail.com');
        return;
      }
    } else if (step === 3) {
      requiredFields = ['Psswd', 'PsswdCon'];
      currentStepFields = requiredFields.filter(field => !formData[field]);
    }

    // Verificar si todos los campos requeridos están completos
    if (currentStepFields.length === 0) {
      // Avanzar al siguiente paso
      setStep(step + 1);
    } else {
      // Mostrar una alerta con los campos faltantes
      toast.warning(`Por favor complete los siguientes campos: ${currentStepFields.join(', ')}`);
    }
  };

  return (
    <section className="Login-section">
      <div className="Login-container">
        <h2>Registrarse</h2>
        {console.log(isMobile)}
        <div className='toast-container'>
          <ToastContainer
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{
              width: isMobile ? "90%" : "35%", // Ancho responsivo según si es móvil o no
              fontSize: "1.2rem", // Tamaño de fuente responsivo
              top: isMobile ? "15%" : "23%", // Posición desde arriba responsiva
              left: isMobile ? "50%" : "82%", // Posición desde la izquierda (centrado horizontalmente)
              transform: isMobile ? "translateX(-50%)" : "translate(-50%, -50%)" // Centrado horizontal y vertical si es móvil
            }}
            closeButton={
              <button
                className="toast-close-button-custom"
                style={{ width: 50, height: 50, borderRadius: 5 }}
              >
                X
              </button>
            }
          />
        </div>
        <div className='formCont'>
          {step === 1 && (
            <form onSubmit={handleSubmit} className='formReg'>
              <span>Datos Generales Paso 1 de 3</span>
              <label htmlFor="nombre">Nombre:</label>
              <input
                type="text"
                id="nombre"
                name="Nombre"
                value={formData.Nombre}
                onChange={handleChange}
                pattern="[A-Za-zÁÉÍÓÚáéíóúÑñ\s]{2,}" // Solo letras y espacios, al menos 2 caracteres
                required
              />
              <label htmlFor="apaterno">Apellido Paterno:</label>
              <input
                type="text"
                id="apaterno"
                name="Apaterno"
                value={formData.Apaterno}
                onChange={handleChange}
                pattern="[A-Za-zÁÉÍÓÚáéíóúÑñ\s]{2,}"
                required
              />
              <label htmlFor="amaterno">Apellido Materno:</label>
              <input
                type="text"
                id="amaterno"
                name="Amaterno"
                value={formData.Amaterno}
                onChange={handleChange}
                pattern="[A-Za-zÁÉÍÓÚáéíóúÑñ\s]{2,}"
                required
              />
              <button type="button" onClick={handleNext}>Siguiente</button>
            </form>
          )}
          {step === 2 && (
            <form onSubmit={handleSubmit} className='form'>
              <span>Datos De Usuario Paso 2 de 3</span>
              <label htmlFor="usuario">Usuario:</label>
              <input
                type="text"
                id="usuario"
                name="Usuario"
                value={formData.Usuario}
                onChange={handleChange}
                pattern="[a-zA-Z0-9]+"
                required
              />
              <label htmlFor="telefono">Teléfono:</label>
              <input
                type="text"
                id="telefono"
                name="Telefono"
                value={formData.Telefono}
                onChange={handleChange}
                pattern="\d{10}" // Exactamente 10 dígitos
                required
              />
              <label htmlFor="email">Correo Electrónico:</label>
              <input
                type="email"
                id="email"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" // Patrón de correo electrónico
                required
              />
              <label htmlFor="pregunta">Pregunta de Seguridad:</label>
              <select
                id="pregunta"
                name="IdPregunta"
                value={formData.IdPregunta}
                onChange={handleChange}
                required
              >
                <option value="">Selecciona una pregunta</option>
                {secretQ.map(quest => (
                  <option key={quest._id} value={quest._id}>
                    {quest.pregunta}
                  </option>
                ))}
              </select>

              <label htmlFor="respuesta">Respuesta de Seguridad:</label>
              <input
                type="text"
                id="respuesta"
                name="Respuesta"
                value={formData.Respuesta}
                onChange={handleChange}
                required
              />
              <button type="button" onClick={handlePrevious}>Anterior</button>
              <button type="button" onClick={handleNext}>Siguiente</button>
            </form>
          )}
          {step === 3 && (
            <form onSubmit={handleSubmit} className='form'>
              <span>Creación Y Confirmación De Contraseña Paso 3 de 3</span>
              <label htmlFor="contrasena">Contraseña:</label>
              <input
                type="password"
                id="contrasena"
                name="Psswd"
                value={formData.Psswd}
                onChange={handleChange}
                pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}" // Patrón de contraseña
                required
              />
              <label htmlFor="contrasena">Confirmar Contraseña:</label>
              <input
                type="password"
                id="contrasena"
                name="PsswdCon"
                value={formData.PsswdCon}
                onChange={handleChange}
                pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[.!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}" // Patrón de contraseña
                required
              />
              <button type="button" onClick={handlePrevious}>Anterior</button>
              <button type="submit">Registrarse</button>
            </form>
          )}
        </div>
      </div>
    </section>
  );
}

export default SignUp;
